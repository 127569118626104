import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  Grid,
  CssBaseline,
  Link,
  CardMedia,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRive, useStateMachineInput } from "rive-react";

import "./login.css";
import {ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import clientConfig from "./Doc/client-Config";
const STATE_MACHINE_NAME = "State Machine 1";
export default function Login() {
  var CryptoJS = require("crypto-js");
  let navigate = useNavigate();
  // const [user,setUser]=useState({email:'',password:''})
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const { rive, RiveComponent } = useRive({
    src: "520-990-teddy-login-screen.riv",
    autoplay: true,
    stateMachines: STATE_MACHINE_NAME,
  });

  useEffect(() => {
    setLook();
  }, [user]);

  const stateSuccess = useStateMachineInput(
    rive,
    STATE_MACHINE_NAME,
    "success"
  );
  const stateFail = useStateMachineInput(rive, STATE_MACHINE_NAME, "fail");
  const stateHandUp = useStateMachineInput(
    rive,
    STATE_MACHINE_NAME,
    "hands_up"
  );

  const stateCheck = useStateMachineInput(rive, STATE_MACHINE_NAME, "Check");
  const stateLook = useStateMachineInput(rive, STATE_MACHINE_NAME, "Look");

  const setHangUp = (hangUp) => {
    stateHandUp && (stateHandUp.value = hangUp);
  };

  const setLook = () => {
    if (!stateLook || !stateCheck || !setHangUp) {
      return;
    }
    setHangUp(false);
    setCheck(true);
    let nbChars = 0;
    if (user) {
      nbChars = parseFloat(user.split("").length);
    }

    let ratio = nbChars / parseFloat(41);
    console.log("ratio " + ratio);

    let lookToSet = ratio * 100 - 25;
    console.log("lookToSet " + Math.round(lookToSet));
    stateLook.value = Math.round(lookToSet);
  };
  const setCheck = (check) => {
    if (stateCheck) {
      stateCheck.value = check;
    }
  };

  if (rive) {
    console.log(rive.contents);
  }

  // const handleChange=(e)=>{
  //     setUser({...user,[e.target.name]: e.target.value})
  // }
  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  const submitForm = (e) => {
    e.preventDefault();
    const siteUrl = clientConfig.siteUrl;
    const sendData = {
      user: user,
      password: CryptoJS.AES.encrypt(
        password,
        "FacultyOnboarding@login$2022$@"
      ).toString(),
    };
    axios
      .post(`${siteUrl}/login`, JSON.stringify(sendData), options, {
        withCredentials: true,
      })
      .then((result) => {
        // console.log(result.data.status);
        if (result.data.status == "200") {
            window.localStorage.setItem('user_id', result.data.user_id);
           
          navigate(`/pre-onboarding`);
        } 
         else if(result.data.status == "400") {
          toast.error('Email and Password are not matched', {
           
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  // console.log(sendData);
  return (
    <>

     <ToastContainer />
      <Box>
        <CardMedia
          component="img"
          image="../accredian.webp"
          alt="green iguana"
          sx={{ width: 120, py: 2, ml: 5 }}
        />
      </Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper
          sx={{
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 3,
            }}
          >
            <Box>
              <RiveComponent
                style={{ width: "200px", height: "200px", borderRadius: "50%" }}
                src="520-990-teddy-login-screen.riv"
              />
            </Box>

            <Box>
              <form autoComplete="off" onSubmit={submitForm}>
                <TextField
                  onFocus={() => setHangUp(false)}
                  onChange={(event) => setUser(event.target.value)}
                  value={user}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  onChange={(event) => {
                    setHangUp(true);
                    setPassword(event.target.value);
                    //setHangUp(false);
                  }}
                  //onFocus={() => setHangUp(true)}
                  //onE

                  value={password}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
              </form>
            </Box>
          </Box>
        </Paper>
      </Container>

      {/* <Box sx={{pt:20}}>
    <Container fixed>
    <Box >

<Box sx={{my:"auto"}}>
<Grid  container spacing={4} justifyContent="center">
    <Grid  item lg={4} sx={{pb:4}}>
    <Paper elevation={3}  >
        <Box>
    <Typography sx={{textAlign:"center",py:2}}>
        Login
    </Typography>
    </Box>
    <Box sx={{mx:2,py:5}}>
<form  onSubmit={submitForm}>
<TextField id="email" label="Email" variant="standard"  type="email"  name="email"  onChange={handleChange}  value={user.email} fullWidth   sx={{mb:2}} />
<TextField id="password" label="Password" variant="standard"   type="password"  name="password"  onChange={handleChange}  value={user.password}  fullWidth sx={{mb:2}}/>
<Button variant="contained" fullWidth    type="submit">Login</Button>


</form>
</Box>
</Paper>
</Grid>
</Grid>
    </Box>
  

</Box>

</Container>
</Box> */}
    </>
  );
}
