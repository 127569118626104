import { Typography,Box,Paper} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
export const Introchecklist = [
{
    id:"DS",
    hadding:"Instructor Preparedness Checklist",
    intro:[<Typography sx={{fontSize:"1.1rem",fontStyle: 'italic'}}>Kindly ensure thorough verification and addressing of the following queries listed below to preempt any potential disruptions during your weekend sessions. Should you require further clarification on any of these points, please do not hesitate to reach out to us at <a href="mailto:academics@accredian.com">academics@accredian.com</a>, and we will promptly assist you.</Typography>],
    Software:"Technical Proficiency",
    Softwareinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Are you proficient with Zoom and its annotation features?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon  sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>If encountering challenges with annotations, have you attempted to utilize the Zoom mobile app on your phone for annotating while conducting sessions via laptop?</Typography></Box>
        </>
    ],
    Environment:"Workspace Environment",
    Environmentinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have access to a distraction-free workspace, particularly on Saturdays and Sundays?</Typography></Box>
        
        </>
    ],
    Time:"Time Commitment",
    Timeinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Can you allocate 2-3 hours per class on both Saturday and Sunday?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Have you adequately prepared for the weekend sessions in advance?</Typography></Box>
        
        </>
    ],
    Internet:"Internet Connectivity",
    Internetinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you possess a reliable internet connection suitable for course access, student/RA communication, and material review? We recommend a minimum of 10 Mbps.</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>If traveling, do you maintain access to a stable 4G network with minimal fluctuations?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you keep your mobile 4G enabled as a backup in the event of router power or internet failure during classes?</Typography></Box>
        
        </>
    ],
    Power:"Power Backup",
    Powerinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have a minimum 2-hour power backup in place (as recommended)?
</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>In the event of power outages, do you possess a generator, UPS, or inverter for backup power?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Have you ensured that your laptop is fully charged before each session?</Typography></Box>
       
        
        </>
    ],
},  
{
    id:"PM",
    hadding:"Instructor Preparedness Checklist",
    intro:[<Typography sx={{fontSize:"1.1rem",fontStyle: 'italic'}}>Kindly ensure thorough verification and addressing of the following queries listed below to preempt any potential disruptions during your weekend sessions. Should you require further clarification on any of these points, please do not hesitate to reach out to us at <a href="mailto:pmacads@accredian.com">pmacads@accredian.com</a>, and we will promptly assist you.</Typography>],
    Software:"Technical Proficiency",
    Softwareinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Are you proficient with Zoom and its annotation features?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon  sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>If encountering challenges with annotations, have you attempted to utilize the Zoom mobile app on your phone for annotating while conducting sessions via laptop?</Typography></Box>
        </>
    ],
    Environment:"Workspace Environment",
    Environmentinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have access to a distraction-free workspace, particularly on Saturdays and Sundays?</Typography></Box>
        
        </>
    ],
    Time:"Time Commitment",
    Timeinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Can you allocate 2-3 hours per class on both Saturday and Sunday?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Have you adequately prepared for the weekend sessions in advance?</Typography></Box>
        
        </>
    ],
    Internet:"Internet Connectivity",
    Internetinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you possess a reliable internet connection suitable for course access, student/RA communication, and material review? We recommend a minimum of 10 Mbps.</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>If traveling, do you maintain access to a stable 4G network with minimal fluctuations?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you keep your mobile 4G enabled as a backup in the event of router power or internet failure during classes?</Typography></Box>
        
        </>
    ],
    Power:"Power Backup",
    Powerinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have a minimum 2-hour power backup in place (as recommended)?
</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>In the event of power outages, do you possess a generator, UPS, or inverter for backup power?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Have you ensured that your laptop is fully charged before each session?</Typography></Box>
       
        
        </>
    ],
}, 
{
    id:"DT",
    hadding:"Instructor Preparedness Checklist",
    intro:[<Typography sx={{fontSize:"1.1rem",fontStyle: 'italic'}}>Kindly ensure thorough verification and addressing of the following queries listed below to preempt any potential disruptions during your weekend sessions. Should you require further clarification on any of these points, please do not hesitate to reach out to us at <a href="mailto:academics@accredian.com">academics@accredian.com</a>, and we will promptly assist you.</Typography>],
    Software:"Technical Proficiency",
    Softwareinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Are you proficient with Zoom and its annotation features?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon  sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>If encountering challenges with annotations, have you attempted to utilize the Zoom mobile app on your phone for annotating while conducting sessions via laptop?</Typography></Box>
        </>
    ],
    Environment:"Workspace Environment",
    Environmentinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have access to a distraction-free workspace, particularly on Saturdays and Sundays?</Typography></Box>
        
        </>
    ],
    Time:"Time Commitment",
    Timeinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Can you allocate 2-3 hours per class on both Saturday and Sunday?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Have you adequately prepared for the weekend sessions in advance?</Typography></Box>
        
        </>
    ],
    Internet:"Internet Connectivity",
    Internetinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you possess a reliable internet connection suitable for course access, student/RA communication, and material review? We recommend a minimum of 10 Mbps.</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>If traveling, do you maintain access to a stable 4G network with minimal fluctuations?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you keep your mobile 4G enabled as a backup in the event of router power or internet failure during classes?</Typography></Box>
        
        </>
    ],
    Power:"Power Backup",
    Powerinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have a minimum 2-hour power backup in place (as recommended)?
</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>In the event of power outages, do you possess a generator, UPS, or inverter for backup power?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Have you ensured that your laptop is fully charged before each session?</Typography></Box>
       
        
        </>
    ],
}, 
{
    id:"PMT",
    hadding:"Instructor Preparedness Checklist",
    intro:[<Typography sx={{fontSize:"1.1rem",fontStyle: 'italic'}}>Kindly ensure thorough verification and addressing of the following queries listed below to preempt any potential disruptions during your weekend sessions. Should you require further clarification on any of these points, please do not hesitate to reach out to us at <a href="mailto:academics@accredian.com">academics@accredian.com</a>, and we will promptly assist you.</Typography>],
    Software:"Technical Proficiency",
    Softwareinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Are you proficient with Zoom and its annotation features?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon  sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>If encountering challenges with annotations, have you attempted to utilize the Zoom mobile app on your phone for annotating while conducting sessions via laptop?</Typography></Box>
        </>
    ],
    Environment:"Workspace Environment",
    Environmentinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have access to a distraction-free workspace, particularly on Saturdays and Sundays?</Typography></Box>
        
        </>
    ],
    Time:"Time Commitment",
    Timeinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Can you allocate 2-3 hours per class on both Saturday and Sunday?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Have you adequately prepared for the weekend sessions in advance?</Typography></Box>
        
        </>
    ],
    Internet:"Internet Connectivity",
    Internetinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you possess a reliable internet connection suitable for course access, student/RA communication, and material review? We recommend a minimum of 10 Mbps.</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>If traveling, do you maintain access to a stable 4G network with minimal fluctuations?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you keep your mobile 4G enabled as a backup in the event of router power or internet failure during classes?</Typography></Box>
        
        </>
    ],
    Power:"Power Backup",
    Powerinfo:[
        <>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Do you have a minimum 2-hour power backup in place (as recommended)?
</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>In the event of power outages, do you possess a generator, UPS, or inverter for backup power?</Typography></Box>
        <Box display="flex"><FiberManualRecordIcon sx={{fontSize:"0.8rem",pt:0.7,pr:2}}/><Typography sx={{fontSize:"1.1rem",}}>Have you ensured that your laptop is fully charged before each session?</Typography></Box>
       
        
        </>
    ],
}, 
];